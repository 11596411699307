<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in partsMap" :key="key" v-show="result.tables[item.keys[0]]" :href="item.keys[0]" :title="item.name" />
                <a-anchor-link href="moxingtu" title="模型图" />
                <a-anchor-link href="canchatu" title="残差图" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">线性回归分析结果</h1>
            <div class="result-area" id="varsProduce">
                <divider-title name="变量说明" />
                <div class="varlist">
                    <div v-for="(item,key) in varsProduce" :key="key">【{{item.typeName}}】{{item.key}}{{item.value}}</div>
                </div>
            </div>

            <div class="result-area" id="formula">
                <divider-title name="方程" />
                {{result.tables.formula}}
            </div>

            <div class="result-area" id="lRStep" v-if="lRStep">
                <divider-title name="逐步回归分析" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px">AIC</th>
                                                    <th style="width:100px">{{toFixedNum(lRStep.aic[0])}}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td colspan="2">逐步</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="result-area" id="modelSummary">
                <divider-title name="模型汇总" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <colgroup><col><col><col><col><col><col><col><col><col></colgroup>
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th key="r" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">R</span></div></span></th>
                                                    <th key="r2" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">R<sup>2</sup></span>                                                        
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.r2.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.r2.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </div></span></th>
                                                    <th key="adjR2" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">调整R<sup>2</sup></span> </div></span></th>
                                                    <th key="stdErrorOfEst" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">估计标准误差</span> </div></span></th>
                                                    <th key="dwStats" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">D-W统计量</span>
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.dwStats.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.dwStats.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </div></span></th>
                                                    <th key="rss" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">回归平方和</span> </div></span></th>
                                                    <th key="ess" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">残差平方和</span> </div></span></th>
                                                    <th key="fstatistics" class=""><span class="ant-table-header-column"><div><span class="ant-table-column-title">F</span> </div></span></th>
                                                    <th key="sigFSta" class="ant-table-row-cell-last"><span class="ant-table-header-column"><div><span class="ant-table-column-title">Sig</span> </div></span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0">
                                                    <td class="">{{modelSummary.r}}</td>
                                                    <td class="">{{modelSummary.r2}}</td>
                                                    <td class="">{{modelSummary.adjR2}}</td>
                                                    <td class="">{{modelSummary.stdErrorOfEst}}</td>
                                                    <td class="">{{modelSummary.dwStats}}</td>
                                                    <td class="">{{modelSummary.rss}}</td>
                                                    <td class="">{{modelSummary.ess}}</td>
                                                    <td class="">{{modelSummary.fstatistics}}</td>
                                                    <td class="">{{modelSummary.sigFSta}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="result-area" id="lRModelCoefficient" v-if="lRModelCoefficient">
                <divider-title name="模型系数" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th key="r" rowspan="2" class=""></th>
                                                    <th colspan="2">未标准化系数</th>
                                                    <th rowspan="2">标准化系数</th>
                                                    <th rowspan="2">t</th>
                                                    <th rowspan="2">Sig.
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.sigt.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.sigt.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th colspan="2">系数置信区间</th>
                                                </tr>
                                                <tr>
                                                    <th>系数</th>
                                                    <th>标准误差</th>
                                                    <th>上限</th>
                                                    <th>下限</th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0">
                                                    <td>{{lRModelCoefficient.coefficentName[0]}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.coefficients[0])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.stdDeviaModelCoe[0])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.stdCoeff[0])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.tstatistics[0])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.sigt[0])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.coeffofConfidence.upperCoeff[0])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.coeffofConfidence.lowerCoeff[0])}}</td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0">
                                                    <td>{{lRModelCoefficient.coefficentName[1]}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.coefficients[1])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.stdDeviaModelCoe[1])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.stdCoeff[1])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.tstatistics[1])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.sigt[1])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.coeffofConfidence.upperCoeff[1])}}</td>
                                                    <td>{{toFixedNum(lRModelCoefficient.coeffofConfidence.lowerCoeff[1])}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 


            <div class="result-area" id="correlationCoefficients" v-if="correlationCoefficients">
                <divider-title name="系数相关性" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>{{correlationCoefficients.indeptVarNames[0]}}</th>
                                                    <th>{{correlationCoefficients.indeptVarNames[1]}}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td rowspan="2">
                                                        相关性
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.correlation.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.correlation.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </td>
                                                    <td>{{correlationCoefficients.indeptVarNames[0]}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.correlation[0][0])}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.correlation[0][1])}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{correlationCoefficients.indeptVarNames[1]}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.correlation[1][0])}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.correlation[1][1])}}</td>
                                                </tr>

                                                <tr>
                                                    <td rowspan="2">
                                                        协方差
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.covariance.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.covariance.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </td>
                                                    <td>{{correlationCoefficients.indeptVarNames[0]}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.covariance[0][0])}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.covariance[0][1])}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{correlationCoefficients.indeptVarNames[1]}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.covariance[1][0])}}</td>
                                                    <td>{{toFixedNum(correlationCoefficients.covariance[1][1])}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="result-area" id="moxingtu">
                <divider-title name="模型图" />
                <chart :chartData="result.lineChart" />
            </div> 

            <div class="result-area" id="canchatu">
                <divider-title name="残差图" />
                <chart :chartData="result.barChart" />
            </div>            

            
        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools';
import DividerTitle from './components/DividerTitle.vue';
import Chart from './components/Chart.vue';
export default {
    components:{DividerTitle,Chart},
    data() {
        return {
            partsMap:[
                {name: '变量说明', keys: ['varsProduce']},
                {name: '方程', keys: ['formula']},
                {name: '逐步回归分析', keys: ['lRStep']},
                {name: '模型汇总', keys: ['modelSummary']},
                {name: '模型系数', keys: ['modelCoefficients']},
                {name: '系数相关性',  keys: ['correlationCoefficients']},
                
            ]
        }
    },
    computed:{
        result() {
            let data = getSessionItem('analysis_lr');
            return data.analysis_lr;
        },
        varsProduce() {
            if(this.result&&this.result.tables.varsProduce) {
                return this.result.tables.varsProduce
            } else {
                return []
            }
        },
        itemInfos() {
            if(this.result&&this.result.itemInfos) {
                return this.result.itemInfos
            } else {
                return {}
            }
        },
        modelSummary() {            
            if(this.result&&this.result.tables.modelSummary) {
                return this.getTableData(this.result&&this.result.tables.modelSummary)
            } else {
                return {}
            }
        },
        lRStep() {
            if(this.result&&this.result.tables.lRStep) {
                return this.result.tables.lRStep;
            } else {
                return null
            }
        },
        lRModelCoefficient() {
            if(this.result&&this.result.tables.modelCoefficients) {
                return this.result.tables.modelCoefficients;
            } else {
                return null
            }
        },
        correlationCoefficients() {
            if(this.result&&this.result.tables.correlationCoefficients) {
                return this.result.tables.correlationCoefficients;
            } else {
                return null
            }
        }
        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                if(typeof num==='number') {
                    return num.toFixed(4);
                } else {
                    return '-'
                }
            } else {
                return '-';
            }
        },       

        getTableData(obj) {
            var data = {}
            for(let i in obj) {
                data[i] = obj[i].toFixed(4)
            }
            return data;
        }
    }
}
</script>